<template>
  <div>
    <template v-if="rooms.length > 0">
      <RoomList @addNewRoom="selectRoomTypeDialog = true" :rooms="rooms" />

      <template v-if="$route.name !== 'ServiceCategoryId'">
        <RoomInfo
          :currentRoom="currentRoom"
          :totalPrice="roomTotalPrice"
          @editRoom="editRoom"
          @deleteRoom="deleteRoom"
        />
        <CategoryList
          v-if="currentRoom.id && roomServices"
          :tree="treeCategorise"
          :roomId="currentRoom.id"
          :roomCategories="currentRoom.categories ? currentRoom.categories : []"
          :roomServices="roomServices"
          :roomProducts="roomProducts"
          :roomCustomCategories="roomCustomCategories"
          :customServices="customServices"
          :quotaRooms="rooms"
          @createRoomCategory="getRooms"
          @createCustomService="onCreateCustomService"
        />
      </template>

      <ServicesOfCategory
        :key="rerenderService"
        v-if="$route.name === 'ServiceCategoryId'"
        :categoryId="`${$route.params.categoryId}`"
        :isCustomServices="$route.params.categoryId === 'custom'"
        :roomId="currentRoom.id"
        :units="units"
        :roomServices="roomServices"
        :roomProducts="roomProducts"
        :roomCustomCategories="roomCustomCategories"
        :customServices="customServices"
        @deleteQuatoRoomService="rerenderService++"
        @updateQuotaRoomService="
          getQuotaRoomServices(currentRoom.id);
          $store.dispatch('quota/getQuotaById', $route.query.quota_id);
        "
        @updateQuotaRoomProducts="
          getQuotaRoomProducts(currentRoom.id);
          $store.dispatch('quota/getQuotaById', $route.query.quota_id);
        "
        @deleteRoomProduct="
          getQuotaRoomProducts(currentRoom.id);
          $store.dispatch('quota/getQuotaById', $route.query.quota_id);
        "
        @updateCustomService="
          onUpdateCustomService();
          $store.dispatch('quota/getQuotaById', $route.query.quota_id);
        "
      />
    </template>

    <SelectRoomTypeModal
      :show="selectRoomTypeDialog"
      @closeModal="selectRoomTypeDialog = false"
      @selectRoomType="selectRoomType"
    />
    <RoomModal
      v-if="currentRoom"
      :show="roomDialog"
      :model="roomMode === 'edit' ? currentRoom : newRoom"
      :mode="roomMode"
      @closeModal="roomDialog = false"
      @saveRoom="saveRoom"
    />
  </div>
</template>

<script>
import { RoomService } from "@/services/room.service.js";
import { QuotaRoomModel } from "@/models/room.model.js";
import { CategoryService } from "@/services/category.service.js";
import { QuotaRoomProductsService } from "@/services/product.service.js";
import {
  RoomServicesService,
  CustomRoomServicesService,
} from "@/services/roomServices.sevice.js";
import getCurrentProjectOwnerId from '@/mixins/getCurrentProjectOwnerId';

export default {
  name: "Room",
  components: {
    RoomList: () => import("./RoomList/RoomList.vue"),
    SelectRoomTypeModal: () =>
      import("./SelectRoomTypeModal/SelectRoomTypeModal.vue"),
    RoomModal: () => import("./RoomModal/RoomModal.vue"),
    RoomInfo: () => import("./RoomInfo/RoomInfo.vue"),
    CategoryList: () => import("~cp/Category/CategoryListOfRoom.vue"),
    ServicesOfCategory: () => import("~cp/Services/Services.vue"),
  },
  mixins: [getCurrentProjectOwnerId],
  data() {
    return {
      selectRoomTypeDialog: this.$route.params.roomId === "new",
      roomDialog: false,
      roomMode: "new",
      currentRoom: new QuotaRoomModel(),
      newRoom: new QuotaRoomModel(),
      rooms: [],
      treeCategorise: [],
      units: [],
      roomServices: [],
      roomProducts: [],
      customServices: [],
      roomCustomCategories: [],
      customCategoryInstance: {
        id: "custom",
        image: null,
        name: "Prestations supplémentaires",
        description: "",
        services: [],
      },
      rerenderService: 0
    };
  },
  computed: {
    roomTotalPrice() {
      let sum = !this.roomServices
        ? 0
        : this.roomServices.reduce((prevValue, currentService) => {
          let productPrice = this.roomProducts.find((product) => product.service_id === currentService.service_id)
          let roomProductPriceWithTax = productPrice?.price_with_tax ? productPrice.price_with_tax : 0
          let roomProductPriceDiscount = productPrice?.discount_price ? productPrice?.discount_price : roomProductPriceWithTax

          let servicePriceWithTax = currentService?.price_with_tax ? currentService.price_with_tax : 0
          let servicePriceDiscount = currentService?.discount_price ? currentService?.discount_price : servicePriceWithTax

            return (
              prevValue +
              roomProductPriceDiscount +
              servicePriceDiscount
            );
          }, 0);
      return `${sum.toFixed(2)}  €`;
    },
  },
  created() {
    if (this.selectRoomTypeDialog) return;
    this.init();
  },
  watch: {
    "$route.params.roomId": {
      immediate: true,
      handler(roomId) {
        this.currentRoom =
          this.rooms.find((room) => room.id === +roomId) || this.currentRoom;
        this.getQuotaRoomServices(this.currentRoom?.id);
        this.getQuotaRoomProducts(this.currentRoom?.id);
      },
    },
    "$route.name": {
      immediate: true,
      handler() {
        this.getQuotaRoomServices(this.currentRoom?.id);
        this.getQuotaRoomProducts(this.currentRoom?.id);
      },
    },
  },
  methods: {
    init() {
      Promise.all([this.getCategoriesTree(), this.getRooms(), this.getUnits()])
        .then(() => {
          this.getQuotaRoomServices(this.currentRoom?.id);
          this.getQuotaRoomProducts(this.currentRoom?.id);
          this.getCustomServices(this.currentRoom?.owner_id);
        })
        .catch((ex) => {
          console.log(ex);
          this.$store.commit(
            "snackbarModule/SET_ERROR_API",
            this.$t("snackbar.error.base")
          );
        });
    },
    selectRoomType({ id, name }) {
      this.newRoom = new QuotaRoomModel({
        name: name,
        edit: true,
        quota_id: this.$route.query.quota_id,
        room_id: id,
      });
      this.roomMode = "new";
      this.roomDialog = true;
    },
    addNewRoom() {
      this.selectRoomTypeDialog = false;
      this.roomDialog = false;
      this.init();
    },
    editRoom() {
      this.roomDialog = true;
      this.roomMode = "edit";
    },
    saveRoom() {
      this.roomDialog = false;
      this.selectRoomTypeDialog = false;
      this.init();
    },
    getRooms() {
      if (!this.$route.query.quota_id) return;
      return RoomService.getQuotaRooms(this.$route.query.quota_id).then(
        ({ data }) => {
          this.rooms = data;
          let roomFromQuery = data.find(
            (room) => room.id === +this.$route.params.roomId
          );

          if (this.rooms.length === 0) {
            this.selectRoomTypeDialog = true;
          }

          if (roomFromQuery) {
            this.currentRoom = roomFromQuery;
          } else if (this.rooms.length > 0) {
            this.$router.push({
              name: this.$route.name,
              params: {
                ...this.$route.params,
                roomId: data[0].id,
              },
              query: {
                ...this.$route.query,
              },
            });
          }
        }
      );
    },
    deleteRoom() {
      if (!this.currentRoom.id) return;
      RoomService.deleteQuotaRoom(this.currentRoom.id)
        .then(() => {
          let to = {
            name: "RoomId",
            params: { roomId: "new" },
            query: {
              project_id: this.$route.query.project_id,
              quota_id: this.$route.query.quota_id,
            },
          };
          if (this.rooms.length > 1) {
            to.params.roomId = this.rooms[0].id || "new";
          }
          this.$store.commit(
            "snackbarModule/SET_SUCCESS_MESSAGE",
            this.$t("snackbar.success.delete")
          );
          this.$router.replace(to);
          this.getRooms();
          this.$store.dispatch(
            "quota/getQuotaById",
            this.$route.query.quota_id
          );
        })
        .catch((data) => {
          this.$store.commit("snackbarModule/SET_ERROR_API", data);
        });
    },
    getCategoriesTree() {
      return CategoryService.getCategoriesTree().then(({ data }) => {
        if (!data) return false;
        this.treeCategorise = Object.keys(data).map((catKey) => {
          let cat = { ...data[catKey] };
          let subcat = cat.children
            ? Object.keys(cat.children).map((subCatKey) => {
                return {
                  ...cat.children[subCatKey],
                };
              })
            : [];
          cat.children = subcat;
          return cat;
        });
      });
    },
    getUnits() {
      return RoomServicesService.getUnits().then(({ data }) => {
        this.units = data;
      });
    },
    getQuotaRoomServices(roomId) {
      if (!roomId) return;
      return RoomServicesService.getQuotaRoomService(roomId).then(
        ({ data }) => {
          this.roomServices = data;
        }
      );
    },
    getQuotaRoomProducts(roomId) {
      if (!roomId) return;
      return QuotaRoomProductsService.getProducts(roomId).then(({ data }) => {
        this.roomProducts = data;
      });
    },
    getCustomServices(id) {
      if (!id) return false;
      let ownerId = !this.$store.getters["authModule/isSuperUser"] ? null : this.$store.getters["project/getCurrentProjectOwnerId"];
      return CustomRoomServicesService.getServices(ownerId).then(({ data }) => {
        this.customServices = data;
        if (this.customServices.length > 0) {
          this.roomCustomCategories = [this.customCategoryInstance];
        }
      });
    },
    onCreateCustomService(customService) {
      this.customServices.push(customService);
      this.getQuotaRoomServices(this.currentRoom?.id);
      this.roomCustomCategories = [this.customCategoryInstance];
    },
    onUpdateCustomService() {
      this.getCustomServices(this.currentRoom?.owner_id);
      this.getQuotaRoomServices(this.currentRoom?.id);
    },
  },
};
</script>

<style>
</style>