import { protectedAxios } from "./helpers";

export class RoomService {

  static getRoomType() {
    return protectedAxios.get(`/room_types/`);
  }

  static getRooms() {
    return protectedAxios.get(`/rooms/`);
  }

  static createQuotaRoom(room) {
    return protectedAxios.post(`/quota_rooms/`, room);
  }

  static updateQuotaRoom({roomId, room}) {
    return protectedAxios.put(`/quota_rooms/${roomId}`, room);
  }

  static deleteQuotaRoom(roomId) {
    return protectedAxios.delete(`/quota_rooms/${roomId}`)
  }

  static getQuotaRooms(quotaId) {
    return protectedAxios.get(`/quota_rooms/`, {
      params: {
        quota_id: quotaId
      }
    });
  }
}
