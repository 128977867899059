import { ProjectService } from "@/services/project.service.js";
export default {
  created() {
    if (
      !this.$store.getters["project/getCurrentProjectOwnerId"] &&
      this.$route.query.project_id
    ) {
      ProjectService.getProjectById(
        +this.$route.query.project_id
      )
        .then(({ data }) => {
          this.$store.commit("project/SET_CURRENT_PROJECT", data);
        })
        .catch((ex) => console.log(ex));
    }
  }
}