export class QuotaRoomModel {
  constructor(room = {}) {
    this.name = room?.name
    this.status = room?.status
    this.quota_id = room?.quota_id
    this.room_id = room?.room_id
    this.length = room?.length || null
    this.width = room?.width || null
    this.height = room?.height || null
    this.custom_square = room?.custom_square || null
  }
}